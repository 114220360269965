<template>
    <div class="demand">
        <div class="main" v-if="isLogin">
         <!--   <div class="side-fixed">

            </div>
            <div class="detail">
                <div class="content">
                    <router-view />
                </div>
            </div>-->
            <el-card class="box-card">
                <div slot="header" class="clearfix">
                    <el-steps :active="stepActive" simple>
                        <el-step title="填写需求" icon="el-icon-edit"></el-step>
                        <el-step title="待工作人员确认" icon="el-icon-success"></el-step>
<!--                        <el-step title="" icon="el-icon-picture"></el-step>-->
                    </el-steps>
                </div>
                <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px" class="demo-ruleForm" v-if="stepActive == 1">
                    <el-form-item label="需求标题："  required prop="title">
                        <el-input v-model="ruleForm.title"></el-input>
                    </el-form-item>
<!--                    <el-form-item label="需求类型：" required prop="type">-->
<!--                        <el-select v-model="ruleForm.type" placeholder="请选择需求类型">-->
<!--                            <el-option label="生物医药" value="生物医药"></el-option>-->
<!--&lt;!&ndash;                            <el-option label="区域二" value="beijing"></el-option>&ndash;&gt;-->
<!--                        </el-select>-->
<!--                    </el-form-item>-->
                    <el-form-item label="需求详情：" required prop="dsc">
                        <el-input type="textarea" v-model="ruleForm.dsc"></el-input>
                    </el-form-item>
                    <el-form-item label="需求所在地：" required prop="areaCode">
                        <el-cascader
                                v-model="ruleForm.areaCode"
                                filterable
                                clearable
                                placeholder="请选择城市"
                                size="medium"
                                :options="options"
                                @change="handleChange">
                        </el-cascader>
                    </el-form-item>
                    <el-form-item label="需求图片/文件：" prop="file_url">
                        <el-upload
                                class="upload-demo"
                                action="#"
                                :before-upload="beforeUpload"
                                :on-preview="handlePreview"
                                :on-remove="handleRemove"
                                :before-remove="beforeRemove"
                                :limit="3"
                                multiple
                                accept=".doc,.docx,.xlsx,.png,.jpg"
                                :on-exceed="handleExceed"
                                :file-list="fileList">
                            <el-button size="small" type="primary">选择文件</el-button>
                            <div slot="tip" class="el-upload__tip">文件格式支持doc/docx/xlsx/png/jpg，文件大小不超过5M</div>
                        </el-upload>
                    </el-form-item>
                    <el-row>
                        <el-col :span="12">
                            <el-form-item label="需求预算："   prop="budget">
                                <el-input v-model="ruleForm.budget" style="width: 200px;margin-right: 5px"></el-input>
                                <span>元</span>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="需求截止日期："   prop="budget">
                                <el-date-picker
                                        style="width: 200px;margin-right: 5px"
                                        v-model="ruleForm.endDate"
                                        type="date"
                                        placeholder="选择日期">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
<!--                    <el-form-item label="需求预算："   prop="budget">-->
<!--                        <el-input v-model="ruleForm.budget" style="width: 200px;margin-right: 5px"></el-input>-->
<!--                        <span>元</span>-->
<!--                    </el-form-item>-->
<!--                    <el-row>-->
<!--                        <el-col :span="10">-->
<!--                            <el-form-item label="手机号码："  required prop="phone">-->
<!--                                <el-input v-model="ruleForm.phone"  style="width: 200px;margin-right: 5px"></el-input>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                        <el-col :span="12">-->
<!--                            <el-form-item label="验证码：" required  prop="code">-->
<!--                                <el-input v-model="ruleForm.code" style="width: 100px;margin-right: 5px"></el-input>-->
<!--                                <el-button style="margin-left: 5px" @click.native="getMobileCode('check', ruleForm.phone)" type="text">{{ times > 0 ? `${times}秒后获取`: '获取验证码'}}</el-button>-->
<!--                            </el-form-item>-->
<!--                        </el-col>-->
<!--                    </el-row>-->

                    <el-row>
                        <el-col :span="10">
                            <el-form-item label="所在单位："   prop="company">
                                <el-input v-model="ruleForm.company" style="width: 200px;margin-right: 5px"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="12">
                            <el-form-item label="邮箱："  required prop="email">
                                <el-input v-model="ruleForm.email" style="width: 200px;margin-right: 5px"></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <el-form-item label="联系人："   prop="contact">
                        <el-input v-model="ruleForm.contact" style="width: 200px;margin-right: 5px"></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="submitForm('ruleForm')">提交需求</el-button>
                        <el-button @click="resetForm('ruleForm')">重置</el-button>
                    </el-form-item>
                </el-form>
                <div v-else>
                    <el-result icon="success" title="需求提交成功" subTitle="您的需求已提交，请耐心等待，您可以">
                        <template slot="extra">
                            <el-button type="primary" @click="stepActive = 1" size="medium">再写一份</el-button>
                            <el-button type="primary" @click="goList()" size="medium">逛逛实验列表</el-button>
                        </template>
                    </el-result>
                </div>
            </el-card>
        </div>
        <div v-else style="margin-top: 150px">
            <el-empty description="登录后可继续操作">
                <el-button type="danger" @click="$login.show()">去登录</el-button>
            </el-empty>
        </div>
    </div>
</template>

<script>
    import {CodeToText, provinceAndCityDataPlus, regionData} from "element-china-area-data";
    import {setTimeDown} from "../../utils/storageUtils";
    import {sendcode, upload} from "../../api/common";
    import {UserService} from "../../api/user";
    import {  mapGetters } from 'vuex'
    export default {
        name: "demand",
        data() {
            return {
                options: regionData,
                stepActive: 1,
                times: 0,
                ruleForm: {
                    title: '',
                    type: '',
                    dsc: '',
                    area: '',
                    areaCode: '',
                    file_url: '',
                    budget: '',
                    endDate: '',
                    // phone: '',
                    company: '',
                    email: '',
                    contact: '',
                    // code: ''
                },
                fileList: [],
                rules: {
                    title: [
                        { required: true, message: '请填写需求标题', trigger: 'blur' },
                    ],
                    type: [
                        { required: true, message: '请选择需求类型', trigger: 'change' }
                    ],
                    dsc: [
                        { required: true, message: '请填写需求详情', trigger: 'blur' },
                    ],
                    areaCode: [
                        { required: true, message: '请选择需求所在地', trigger: 'change' }
                    ],
                    // phone: [
                    //     { required: true, message: '请填写手机号码', trigger: 'blur' },
                    // ],
                    // code: [
                    //     { required: true, message: '请填写手机验证码', trigger: 'blur' },
                    // ],
                    email: [
                        { required: true, message: '请填写邮箱', trigger: 'blur' },
                    ],
                }
            }
        },
      computed: {
        ...mapGetters(['isLogin']),
      },
        methods: {
            goList() {
                this.$router.push({
                    name: 'list'
                })
            },
            countdown() {
                this.times = 60
                // eslint-disable-next-line no-unused-vars
                const down = () => {
                    setTimeout(() => {
                        this.times--
                        setTimeDown(this.times)
                        if (this.times > 0) {
                            down()
                        }
                    }, 1000)
                }
                down()

            },
            getMobileCode(event, mobile) {
                if (this.times > 0) return
                sendcode({ event, mobile }).then(res => {
                    // // console.log('res', res)
                    if (res.code) {
                        this.$message.success('验证码发送成功')
                        this.countdown()
                    } else {
                        this.$message.error(res.msg)

                    }
                    // this.captchaUrl = res.data
                })
            },
            handleRemove(file, fileList) {
                // // console.log(file, fileList);
            },
            handlePreview(file) {
                // // console.log(file);
            },
            beforeUpload(file) {
                // // console.log(file);
                const forms = new FormData()
                forms.append('file', file)
                upload(forms).then(res => {
                    if (res.code == 1) {
                        this.fileList.push({
                          name: file.name,
                          url: this.$imgurl + res.data.url
                        })
                        // this.ruleForm.file_url = res.data.url
                    } else {
                        this.$message.error(res.msg)
                    }

                })
            },
            handleExceed(files, fileList) {
                this.$message.warning(`当前限制选择 3 个文件，本次选择了 ${files.length} 个文件，共选择了 ${files.length + fileList.length} 个文件`);
            },
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${ file.name }？`);
            },
            submitForm(formName) {
                this.ruleForm.file_url = (this.fileList.map(item => item.url)).join(',')
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.$confirm(`确定提交需求？`).then(res => {
                            const params = {...this.ruleForm, ...{}}
                            params['endDate'] = new Date(params.endDate).getTime() / 1000
                            UserService.adddemand(params).then(res => {
                                if (res.code == 1) {
                                    this.stepActive = 2
                                } else {
                                    this.$message.error(res.msg)
                                }
                            })
                        })
                    } else {
                        // // console.log('error submit!!');
                        return false;
                    }
                });


            },
            handleChange(item) {
                this.ruleForm.area = CodeToText[item[0]]+CodeToText[item[1]]+CodeToText[item[2]]

            },
            resetForm(formName) {
                this.$confirm(`确定重置已输入数据？`).then(res => {
                    this.$refs[formName].resetFields();
                })

            }
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">
    .box-card{
        width: 800px;
        min-height: 800px;
        margin: 0 auto;
    }
</style>